import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Authentication",
  "path": "/docs/authentication",
  "date": "2020-03-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Getting and using tokens`}</h1>
    <h2>{`Authentication`}</h2>
    <p>{`enableHR uses the OAuth 2.0 authorization code flow to issue API access tokens on behalf of users.`}</p>
    <p>{`At a high-level, this flow has the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Your application directs the browser to the enableHR authorization page.`}</li>
      <li parentName="ul">{`The user authenticates and approves your application's request.`}</li>
      <li parentName="ul">{`The user is redirected back to your application with an authorization code in the query string.`}</li>
      <li parentName="ul">{`Your application sends this code to enableHR to exchange for an API access token and optionally a refresh token.`}</li>
      <li parentName="ul">{`Your application can now use these tokens to call the enableHR API on behalf of the user.`}</li>
    </ul>
    <h3>{`1.Redirect to the enableHR authorization URL.`}</h3>
    <p>{`Your application should redirect the user to the authorization URL`}</p>
    <p><a parentName="p" {...{
        "href": "https://login.enablehr.com/oauth/authorize"
      }}>{`https://login.enablehr.com/oauth/authorize`}</a></p>
    <p>{`The following query parameters should be passed with the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`response_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`code (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`client_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`issued when you created your application (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`scope`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`permissions to request, see Scopes (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`redirect_uri`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL to redirect back to (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`unique string to be passed back (optional)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The scope parameter is a space-separated list of OAuth scopes indicating the desired access. See Scopes for the complete list of scopes.`}</p>
    <p>{`The redirect_uri parameter must match one of the URLs specified when the client_id was issued.`}</p>
    <p>{`The state parameter can be provided to carry through server-specific state. For example, you can use this to help prevent forgery attacks.`}</p>
    <p>{`The user will be presented with a login screen, and then a confirmation screen where they will grant your application access to their EnableHR data.`}</p>
    <h3>{`2. Receive an authorization code from enableHR.`}</h3>
    <p>{`After a successful login, the authorization server will redirect to the URL specified in step 1 passing the following parameters passed with the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a temporary authorization code`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the unique string specified in step 1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If you passed a state parameter in step 1 then at this point you should compare the state parameter from this request with the state parameter from the request in step 1. If the states don't match then the request may have been created by an unknown third party and you should abort the process.`}</p>
    <p>{`If your request for access is denied by the user then the user will be redirected to the URL specified in step 1 with the following query parameters passed with the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`access_denied`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`3. Exchange the authorization code for an access token.`}</h3>
    <p>{`The authorization code received in step 2 will remain valid for 5 minutes and should be exchanged for an access token. To do this, submit a POST request to the token URL:`}</p>
    <p><a parentName="p" {...{
        "href": "https://login.enablehr.com/oauth/token"
      }}>{`https://login.enablehr.com/oauth/token`}</a></p>
    <p>{`The following parameters should be included in the POST request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`client_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`issued when you created your application (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`client_secret`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`issued when you created your application (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`grant_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authorization_code (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`redirect_uri`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the URL specified in step 1 (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authorisation code given after successfully entering a username and password.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The client_id and client_secret need to be provided as an Authorization header with the request, for example`}</p>
    <p>{`Authorization: Basic dGVzdGluZ19jbGllbnRfaWQ6dGVzdGluZ19jbGllbnRfc2VjcmV0`}</p>
    <p>{`The value dGVzdGluZ19jbGllbnRfaWQ6dGVzdGluZ19jbGllbnRfc2VjcmV0 is the client_id:client_secret base64 encoded`}</p>
    <p>{`This request will return a JSON response containing the access token, for example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "access_token": "test_access_token_in_jwt_format",
    "token_type": "bearer",
    "refresh_token": "test_refresh_token"
    "expires_in": "1799",
    "scope": "manage:employees read:accounts:referencedata",
    "accountIds": [],
    "userId": "ea71599908794e6b9eaf7ff84dbcd8cf",
    "jti": "ovLgx1bV/aRWVGNylJ4zgQn+p6Q="
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`access_token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`token to be used as the Bearer token in the Authorization header of all API requests`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`expires_in`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`expiration time of the access token in seconds since the response was generated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`refresh_token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`token to be used to request new access tokens`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`scope`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`permissions to request, see Scopes (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`accountIds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`account ids associated with this user)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`userid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`user id of the logged in user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`jti`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The "jti" (JWT ID) claim provides a unique identifier for the JWT.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`4. Exchange a refresh token for an access token.`}</h3>
    <p>{`The access token has a limited lifetime (currently 30 minutes). Extended access, can be requested by requesting a new access token using the refresh token. To do this, submit a POST request to the token URL:`}</p>
    <p><a parentName="p" {...{
        "href": "https://login.enablehr.com/oauth/token"
      }}>{`https://login.enablehr.com/oauth/token`}</a></p>
    <p>{`The following parameters should be included in the POST request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`grant_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`refresh_token (required)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`refresh_token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the refresh token returned in step 3`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`client_id`}</inlineCode>{` and `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{` should be sent as a HTTP Basic Auth header as the username and password respectively.`}</p>
    <p>{`This request will return a JSON response containing a new access token and request token.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "access_token": "test_access_token_in_jwt_format",
    "token_type": "bearer",
    "refresh_token": "test_refresh_token"
    "expires_in": "1799",
    "scope": "manage:employees read:accounts:referencedata",
    "accountIds": [],
    "userId": "ea71599908794e6b9eaf7ff84dbcd8cf",
    "jti": "ovLgx1bV/aRWVGNylJ4zgQn+p6Q="
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      